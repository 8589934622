<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("menu.modules") }}
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.module.source.group.title')"
        :subTitle="$t('pages.module.source.group.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.source.category.title')" placement="top">
                    <router-link :to="'/module/source/group/' + record.id+ '/category'" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_source_group" ref="sourceGroupModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="sourceGroupForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.source.group.cols.alias') }}</label>
                                <el-form-item prop="alias" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.alias" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.source.group.cols.name') }}</label>
                                <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.name" type="text"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    class: "",
                    key: "id"
                },
                {
                    name: this.$t("pages.module.source.group.cols.alias"),
                    key: "alias"
                },
                {
                    name: this.$t("pages.module.source.group.cols.name"),
                    key: "name"
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.module.source.group.table;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.source.group.title"), [this.$t("menu.moduleManagement")]);
        this.$store.dispatch('module/source/group/get', {page: {}});
    },
    methods:{
        newRecord(){
            this.form.updateStatus = false;
            this.form.data = {};
            this.form.title = this.$t("pages.module.source.group.new");
            this.showModal(this.$refs.sourceGroupModal);
        },
        fetchRecord(record) {
            this.form.updateStatus = true;
            this.form.title =  this.$t("pages.module.source.group.edit")
            this.axios.get(this.endpoints['module_source_group']+ '/' + record.id).then(response => {
                this.form.data = response.data.data;
                this.showModal(this.$refs.sourceGroupModal);
            });
        },
        onSubmit(){
            this.$refs.sourceGroupForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['module_source_group'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.sourceGroupModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['module_source_group'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.sourceGroupModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("module/source/group/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/source/group/refresh");
        },
        disabledDate(date){
            return date.getTime() < Date.now()
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/source/group/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        }
    }
}
</script>

<style scoped>

</style>